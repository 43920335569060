<template>
  <div class="mts-registration-form">
    <template v-if="!formSubmissionResult">
      <h2 class="mts-registration-form__title" v-html="formTitle"></h2>

      <p
          v-if="formDescription"
          class="mts-registration-form__description"
          v-html="formDescription"
      ></p>

      <form
          :id="formName"
          :name="formName"
          action=""
          :data-status="formStatus"
          :class="[
          'mts-registration-form__form',
          { loading: isLoading === true },
        ]"
          @submit="onSubmit"
      >
        <input
            :id="'formid_' + formName"
            type="hidden"
            name="formid"
            class="inp-text"
            :value="formIdValue"
        />
        <input :id="'fio_' + formName" type="hidden" name="fio"/>
        <input :id="'google_cid_' + formName" type="hidden" name="google_cid"/>
        <input
            :id="'locale_' + formName"
            type="hidden"
            name="locale"
            value="ru"
        />
        <input
            v-if="formUserComment"
            id="question"
            :value="formUserComment"
            type="hidden"
            name="question"
        />

        <div class="mts-registration-form__form-fields">
          <div class="mts-registration-form__field">
            <VInputMTS
                id="name"
                v-model="form.firstname"
                name="name"
                placeholder="Имя"
                autocomplete="name"
                type="text"
                :class="[
                'xl',
                getErrors('firstname') && $v.form['firstname'].$dirty
                  ? 'error'
                  : '',
              ]"
                @focus="$v.form['firstname'].$reset()"
            />
            <VErrorMTS v-if="getErrors('firstname')">{{
                getErrors('firstname')
              }}
            </VErrorMTS>
          </div>

          <div class="mts-registration-form__field">
            <VInputMTS
                id="last_name"
                v-model="form.lastname"
                name="last_name"
                placeholder="Фамилия"
                autocomplete="family-name"
                type="text"
                :class="[
                'xl',
                getErrors('lastname') && $v.form['lastname'].$dirty
                  ? 'error'
                  : '',
              ]"
                @focus="$v.form['lastname'].$reset()"
            />
            <VErrorMTS v-if="getErrors('lastname')">{{
                getErrors('lastname')
              }}
            </VErrorMTS>
          </div>

          <div class="mts-registration-form__field">
            <VInputMTS
                id="email"
                v-model="form.email"
                name="email"
                placeholder="E-mail"
                autocomplete="email"
                type="text"
                :class="[
                'xl',
                getErrors('email') && $v.form['email'].$dirty ? 'error' : '',
              ]"
                @focus="$v.form['email'].$reset()"
            />
            <VErrorMTS v-if="getErrors('email')">{{
                getErrors('email')
              }}
            </VErrorMTS>
          </div>

          <div class="mts-registration-form__field">
            <VPhoneInput
                @getValid="getValidPhone"
                :class="[getErrors('phone') && $v.form['phone'].$dirty ? 'error' : '' ]"
                :get-errors="getErrors('phone')"
            />
          </div>

          <div class="mts-registration-form__field">
            <VPasswordInputMTS
                id="password"
                v-model="form.password"
                name="password"
                type="password"
                placeholder="Придумайте пароль"
                autocomplete="new-password"
                :class="[
                'xl',
                getErrors('password') && $v.form['password'].$dirty
                  ? 'error'
                  : '',
              ]"
                @focus="$v.form['password'].$reset()"
            />
            <VErrorMTS
                v-if="getErrors('password') && $v.form['password'].$dirty"
            >{{ getErrors('password') }}
            </VErrorMTS
            >
          </div>

          <div class="mts-registration-form__field">
            <VDropdownMTS
                id="purpose_of_use"
                v-model="form.purpose_of_use"
                name="purpose_of_use"
                label="Цель использования сервисов"
                :options="purposeOfUseList"
                :class="['xl', getErrors('purpose_of_use') ? 'error' : '']"
                @focus="$v.form['purpose_of_use'].$reset()"
            />
            <VErrorMTS
                v-if="
                getErrors('purpose_of_use') && $v.form['purpose_of_use'].$dirty
              "
            >{{ getErrors('purpose_of_use') }}
            </VErrorMTS>
          </div>

          <div v-if="isShowNumberDropdown" class="mts-registration-form__field">
            <VDropdownMTS
                id="number_of_employees_select"
                v-model="form.number_of_employees_select"
                name="number_of_employees_select"
                label="Сколько сотрудников в компании?"
                :options="number_of_employees"
                :class="[
                'xl',
                getErrors('number_of_employees_select') ? 'error' : '',
              ]"
                @focus="$v.form['number_of_employees_select'].$reset()"
            />
            <VErrorMTS v-if="getErrors('number_of_employees_select')"
            >{{ getErrors('number_of_employees_select') }}
            </VErrorMTS>
          </div>

          <input
              v-model="numberOfEmployees"
              name="number_of_employees"
              hidden
          />

          <div
              v-if="!formShort && isShowNumberDropdown"
              class="mts-registration-form__field"
          >
            <VDropdownMTS
                v-model="form.user_role_select"
                name="'user_role_select'"
                label="Ваша роль в компании?"
                :options="user_role"
                :class="['xl', getErrors('user_role_select') ? 'error' : '']"
                @focus="$v.form['user_role_select'].$reset()"
            />
            <VErrorMTS v-if="getErrors('user_role_select')">{{
                getErrors('user_role_select')
              }}
            </VErrorMTS>
          </div>

          <input v-model="userRole" name="user_role" hidden/>
        </div>

        <div class="mts-registration-form__submit">
          <VButtonMTS
              type="submit"
              class="mts-registration-form__btn --l"
              :class="formBtnColor"
          >
            {{ formBtnText }}
          </VButtonMTS>
        </div>

        <div v-if="formShowInfo" class="mts-registration-form__info">
          Нажимая на&nbsp;кнопку «{{ formBtnText }}», вы&nbsp;принимаете условия
          <a target="_blank" href="/legal/license-agreement.pdf">оферты</a>,
          соглашаетесь
          <a target="_blank" href="/legal/private-agreement-soft.pdf"
          >на&nbsp;обработку персональных данных</a
          >
          и&nbsp;связь с&nbsp;вами способами, указанными в&nbsp;оферте,
          в&nbsp;целях исполнения условий оферты.
        </div>
      </form>
    </template>
    <template v-else>
      <MTSFormMessage
          :type-message="statusFormText"
          :email="form.email"
          :success="success"
      />
    </template>
  </div>
</template>

<script>
import SubmitGatewayMixin from '@/mixins/SubmitGatewayMixin'
import VInputMTS from '@/components/Common/MTS/VInputMTS'
import VPasswordInputMTS from '@/components/Common/MTS/VPasswordInputMTS'
import VButtonMTS from '@/components/Common/MTS/VButtonMTS'
import VDropdownMTS from '@/components/Common/MTS/VDropdownMTS.vue'
import VErrorMTS from '@/components/Common/MTS/VErrorMTS.vue'
import FormOptions from '@/mixins/FormOptions'
import ValidationFields from '@/mixins/ValidationFields'
import MTSFormMessage from '@/components/Common/Form/MTSFormMessage'
import VPhoneInput from '@/components/Feature/VPhoneInput.vue';

export default {
  name: 'MTSRegistrationUniversalForm',
  components: {
    VPhoneInput,
    MTSFormMessage,
    VInputMTS,
    VPasswordInputMTS,
    VDropdownMTS,
    VButtonMTS,
    VErrorMTS
  },
  mixins: [FormOptions, ValidationFields, SubmitGatewayMixin],
  props: {
    formName: {
      type: String,
      default: 'webinar'
    },
    formTitle: {
      type: String,
      default: `Регистрация<br>аккаунта организатора`
    },
    formDescription: {
      type: String,
      default: ``
    },
    formIdValue: {
      type: String,
      default: ''
    },
    formBtnText: {
      type: String,
      default: 'Завершить регистрацию'
    },
    formBtnColor: {
      type: String,
      default: '--violet'
    },
    formShowInfo: {
      type: Boolean,
      default: true
    },
    formUserComment: {
      type: String,
      default: ''
    },
    formOptionsType: {
      type: String,
      default: ''
    },
    formShort: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        purpose_of_use: '',
        number_of_employees_select: '',
        user_role_select: !this.formShort
      },
      usrReferer: false,
      isShowNumberDropdown: false
    }
  },
  computed: {
    purposeDropdown () {
      return this.form.purpose_of_use
    },
    purposeOfUseList () {
      switch (this.formOptionsType) {
        case 'short':
          return this.purpose_of_use_short;
        case 'student':
          return this.purpose_of_use_student;
        default:
          return this.purpose_of_use;
      }
    },
    numberOfEmployees () {
      switch (this.form.purpose_of_use) {
        case 'work':
          return this.form.number_of_employees_select;
        case 'student':
          return '';
        default:
          return this.form.purpose_of_use;
      }
    },
    userRole () {
      switch (this.purposeDropdown) {
        case 'personal_use':
          return 'Физическое лицо'
        case 'participant':
          return ''
        default:
          return this.form.user_role_select
      }
    },
    formSubmissionResult () {
      return this.formName === this.idFormOnResult ? this.statusForm : false
    }
  },
  watch: {
    purposeDropdown () {
      this.isShowNumberDropdown = this.purposeDropdown === 'work'
    }
  },
  methods: {
    getValidPhone (validPhone) {
      this.form.phone = validPhone
    }
  }
}
</script>

<style lang="scss" scoped>
.mts-registration-form {
  &__form {
    display: flex;
    flex-direction: column;

    &-fields {
      display: grid;
      grid-template-columns: 100%;
      row-gap: 16px;
    }
  }

  &__title {
    font-family: $font-mts-compact;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    margin-bottom: 16px;
  }

  &__description {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__btn {
    width: 100%;
    margin: 20px auto 8px;

    &.disabled {
      background: rgba(188, 195, 208, 0.5);
      color: #969fa8;
    }
  }

  &__info {
    color: $color-gray-1;
    font-family: $font-mts-compact;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    text-align: center;

    a {
      text-decoration: none;
      color: inherit;
      border-bottom: 1px solid $color-gray-1;

      &:hover {
        border-bottom: none;
      }
    }
  }

  .loading button.mts-registration-form__btn {
    background-image: url('~/assets/img/loader-mts.svg');
    background-color: rgba(188, 195, 208, 0.5);
    background-size: 7%;
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
  }
}
</style>
